import React, { useState, useCallback, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import FormInput from "../../components/FormInput"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Sidebar from "../../components/CompanyProfileSidebar"
import Modal from "../../components/Modal"
import ModalModules from "../../components/ModalModules"
import ImageDefault from "../../assets/images/default-hero-bg.jpg"
import logoDefault from "../../assets/images/company-profile-placeholder.png"
import { MultiSelect } from "react-multi-select-component"
import FeaturedStories from "../../components/FeaturedStories"
import { getAll as getCompanyProfile, create as createCompanyProfiile, update as updateProfile } from "../../dataAccess/wordPress/companyProfiles"
import { Notification } from "../../components/ToastNotification"

const CompanyProfileEdit = ({ location, msPerksBenefitsJSONData, msPerksBenefitsStringData, logoImage, backgroundImage }) => {
  const logoModal = useRef()
  const headerModal = useRef()

  const [data, setMemberData] = useState("")
  const [transferedImage, setTransferedImage] = useState("")
  const [transferedColor, setTransferedColor] = useState("")
  const [transferedBgImage, setTransferedBgImage] = useState("")
  const [transferedBgColor, setTransferedBgColor] = useState("")
  const [selectPerks, setPerksSelected] = useState([])
  const [publishingProfile, setPublishingProfile] = useState(false)
  const [isPrimary, setIsPrimary] = useState(false)

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Company Profile Edit",
  })

  let membershipName
  let memberstackId

  if (data) {
    membershipName = data.membership.name
    memberstackId = data.id
  }

  /* start These should probably be in a ACF */

  const options = [
    { label: "Education", value: "Education" },
    { label: "Finance / Insurance", value: "Finance / Insurance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Logistics", value: "Logistics" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "News / Entertainment", value: "News / Entertainment" },
    { label: "Retail", value: "Retail" },
    { label: "Tech Services", value: "Tech Services" },
    { label: "Technology", value: "Technology" },
    { label: "Travel / Hospitality", value: "Travel / Hospitality" },
    { label: "Utilities / Water Tech", value: "Utilities / Water Tech" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Other", value: "Other" },
  ]

  const companySize = [
    { label: "1-50", value: "1-50" },
    { label: "51-250", value: "51-250" },
    { label: "251-500", value: "251-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1000+", value: "1000+" },
  ]

  const perksBenefits = [
    { label: "Flexible Schedule", value: "Flexible Schedule" },
    { label: "Remote Work Options", value: "Remote Work Options" },
    { label: "Diversity Employee Resource Groups", value: "Diversity Employee Resource Groups" },
    { label: "Health Benefits", value: "Health Benefits" },
    { label: "Dental & Vision Benefits", value: "Dental & Vision Benefits" },
    { label: "401k / HSA / Retirement", value: "401k / HSA / Retirement" },
    { label: "Paid Time Off", value: "Paid Time Off" },
    { label: "Wellness Programs", value: "Wellness Programs" },
    { label: "Mental Health Resources (EAP)", value: "Mental Health Resources (EAP)" },
    { label: "Company Outings", value: "Company Outings" },
    { label: "Food / Stock Kitchen", value: "Food / Stock Kitchen" },
    { label: "Games Recreation", value: "Games Recreation" },
    { label: "Business Casual Attire", value: "Business Casual Attire" },
    { label: "Training Budget", value: "Training Budget" },
    { label: "Stock Options", value: "Stock Options" },
    { label: "Parking + Transportation Options", value: "Parking + Transportation Options" },
    { label: "Relocation Packages", value: "Relocation Packages" },
  ]

  const states = [
    { label: "AL", value: "AL" },
    { label: "AK", value: "AK" },
    { label: "AR", value: "AR" },
    { label: "AZ", value: "AZ" },
    { label: "CA", value: "CA" },
    { label: "CO", value: "CO" },
    { label: "CT", value: "CT" },
    { label: "DC", value: "DC" },
    { label: "DE", value: "DE" },
    { label: "FL", value: "FL" },
    { label: "GA", value: "GA" },
    { label: "HI", value: "HI" },
    { label: "IA", value: "IA" },
    { label: "ID", value: "ID" },
    { label: "IL", value: "IL" },
    { label: "IN", value: "IN" },
    { label: "KS", value: "KS" },
    { label: "KY", value: "KY" },
    { label: "LA", value: "LA" },
    { label: "MA", value: "MA" },
    { label: "MD", value: "MD" },
    { label: "ME", value: "ME" },
    { label: "MI", value: "MI" },
    { label: "MN", value: "MN" },
    { label: "MO", value: "MO" },
    { label: "MS", value: "MS" },
    { label: "MT", value: "MT" },
    { label: "NC", value: "NC" },
    { label: "NE", value: "NE" },
    { label: "NH", value: "NH" },
    { label: "NJ", value: "NJ" },
    { label: "NM", value: "NM" },
    { label: "NV", value: "NV" },
    { label: "NY", value: "NY" },
    { label: "ND", value: "ND" },
    { label: "OH", value: "OH" },
    { label: "OK", value: "OK" },
    { label: "OR", value: "OR" },
    { label: "PA", value: "PA" },
    { label: "RI", value: "RI" },
    { label: "SC", value: "SC" },
    { label: "SD", value: "SD" },
    { label: "TN", value: "TN" },
    { label: "TX", value: "TX" },
    { label: "UT", value: "UT" },
    { label: "VT", value: "VT" },
    { label: "VA", value: "VA" },
    { label: "WA", value: "WA" },
    { label: "WI", value: "WI" },
    { label: "WV", value: "WV" },
    { label: "WY", value: "WY" },
  ]

  const imageData = [
    {
      logo: {
        src: data.logo,
        bgcolor: data["logo-background-color"],
        class: "uploaded-image--logo",
        width: 298,
        height: 298,
      },
    },
    {
      header: {
        src: data["background-image"],
        bgcolor: data["background-color"],
        class: "uploaded-image--header",
        width: 1280,
        height: 400,
      },
    },
  ]
  let acfIsPrimary

  const handleChecked = () => {
    if (!isPrimary) {
      data.isprimary = ""
      acfIsPrimary = "off"
    } else {
      data.isprimary = "on"
      acfIsPrimary = "on"
    }
  }

  if (transferedImage) {
    data.logo = transferedImage
  }
  if (transferedColor) {
    data["logo-background-color"] = transferedColor
  }

  if (transferedBgImage) {
    data["background-image"] = transferedBgImage
  }
  if (transferedBgColor) {
    data["background-color"] = transferedBgColor
  }

  const save = async (data, e) => {
    window.MemberStack.onReady.then(function (member) {
      member.updateProfile(data)
    })
  }

  useEffect(() => {
    if (data) {
      if (data["perks-benefits"]) {
        msPerksBenefitsStringData = data["perks-benefits"]
        try {
          msPerksBenefitsJSONData = JSON.parse(msPerksBenefitsStringData)
          setPerksSelected([...msPerksBenefitsJSONData])
        } catch {}
      }
    }

    let divs = document.querySelectorAll("[data-ms-content]")

    ;[].forEach.call(divs, function (div) {
      div.classList.add("ms-ready")
    })
  }, [data["perks-benefits"]])

  if (data != undefined) {
    if (data.logo) {
      logoImage = <img src={data.logo} alt={data["company-name"]} />
    } else {
      logoImage = <img src={logoDefault} alt={data["company-name"]} />
    }

    if (data["background-image"]) {
      backgroundImage = <img src={data["background-image"]} alt={data["company-name"]} />
    } else {
      backgroundImage = <img src={ImageDefault} alt={data["company-name"]} />
    }
  }
  const [allProfiles, setAllProfiles] = useState([])

  useEffect(() => {
    async function setProfile() {
      setAllProfiles(await getCompanyProfile())
    }
    setProfile();
    
  }, [])

  const publishCompanyProfile = async (e) => {
    e.preventDefault()

    setPublishingProfile(true)
    const formData = new FormData(e.target)
    const obj = {}

    for (const entry of formData.entries()) {
      obj[entry[0]] = entry[1]
    }

    const saveToMemberStack = {
      city: obj.city,
      "company-description": obj["company-description"],
      "company-name": obj["company-name"],
      "company-url": obj["company-url"],
      "total-of-employees": obj["total-of-employees"],
      "general-contact-email-address": obj["general-contact-email-address"],
      industry: obj.industry,
      "perks-benefits": JSON.stringify(selectPerks),
      "phone-number": obj["phone-number"],
      state: obj.state,
      address: obj.address,
      zip: obj.zip,
      "background-color": obj["background-color"],
      "logo-background-color": obj["logo-background-color"],
      "company-name": obj["company-name"],
      logo: obj.logo,
      "company-name": obj["company-name"],
      "background-image": obj["background-image"],
      linkedin: obj.linkedin,
      facebook: obj.facebook,
      twitter: obj.twitter,
      isprimary: data.isprimary,
    }

    for (let item in allProfiles) {
      if (allProfiles[item].companyProfiles.memberstackId.trim() === memberstackId) {
        const fullProfile = await getCompanyProfile(allProfiles[item].databaseId)
        await updateProfile(allProfiles[item].databaseId, {
          ...fullProfile,
          modified: new Date(),
          title: obj["company-name"],
          slug: obj["company-name"],
          fields: {
            ...fullProfile.acf,

            city: obj.city,
            company_description: obj["company-description"],
            company_name: obj["company-name"],
            company_url: obj["company-url"],
            company_size: obj["total-of-employees"],
            contact_email: obj["general-contact-email-address"],
            industry: obj.industry,
            perks_benefits: JSON.stringify(selectPerks),
            phone_number: obj["phone-number"],
            state: obj.state,
            street_address: obj.address,
            zip: obj.zip,
            background_color: obj["background-color"],
            logo_background_color: obj["logo-background-color"],
            logo_alt: obj["company-name"],
            logo: obj.logo,
            background_image_alt: obj["company-name"],
            background_image: obj["background-image"],
            linkedin: obj.linkedin,
            facebook: obj.facebook,
            twitter: obj.twitter,
            membership_type: membershipName,
            primary_user: acfIsPrimary,
          },
        })
        Notification("success! Your Company Profile has been updated.", "fas fa-check-circle", "success")

        setPublishingProfile(false)
        save(saveToMemberStack)
        return
      }
    }

    await createCompanyProfiile(memberstackId, obj.city, obj["company-description"], obj["company-name"], obj["company-url"], obj["total-of-employees"], obj["general-contact-email-address"], obj.industry, selectPerks, obj["phone-number"], obj.state, obj.address, obj.zip, obj["background-color"], obj["logo-background-color"], obj["company-name"], obj.logo, obj["company-name"], obj["background-image"], obj.linkedin, obj.facebook, obj.twitter, membershipName, obj.isprimary)

    Notification("success! Your Company Profile is now Public.", "fas fa-check-circle", "success")
    setAllProfiles(await getCompanyProfile())
    setPublishingProfile(false)
    save(saveToMemberStack)
  }

  let socialNetwork = ""
  const notBaseorEntry = membershipName !== "Entry" || membershipName !== "Base" || membershipName !== "Community (Entry)" || membershipName !== "Bronze (Base)"
  if (notBaseorEntry) {
    socialNetwork = (
      <div className="social-network" data-ms-content="!entry">
        <h3>Social Networks</h3>
        <p>Adding company's social network also helps other to connect.</p>
        <br />
        <div className="social-network--input">
          <FormInput type="textgroup" id="linkedin" name="linkedin" icon="fab fa-linkedin-in" memberstack="linkedin" />
          <FormInput type="textgroup" id="facebook" name="facebook" icon="fab fa-facebook-f" memberstack="facebook" />
          <FormInput type="textgroup" id="twitter" name="twitter" icon="fab fa-twitter" memberstack="twitter" />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Seo title="Company Profile" />
      <Breadcrumbs crumbs={crumbs} />
      <div className="profiles profiles--edit container">
        <div className="row profile__layout">
          <Sidebar sidebar={data} />

          <div className="content">
            <form onSubmit={publishCompanyProfile}>
              <div className="content__header">
                <h1>Welcome {data["company-name"]}!</h1>
                <h3>
                  {membershipName} Member {membershipName !== "Premium" && <Link to="/recruit-with-us">Upgrade</Link>}
                </h3>

                <div className={`upload--file ${membershipName === "Entry" || membershipName === "Community (Entry)" ? "entry--backgroundImage" : ""}`}>
                  <div className="uploaded--file">
                    <input type="hidden" name="background-image" data-ms-member="background-image" defaultValue={data["background-image"]} />
                    <input type="hidden" name="background-color" data-ms-member="background-color" defaultValue={data["background-color"]} />
                    <label htmlFor="upload-background-image" className="image--file image--file__background" onClick={() => headerModal.current.openModal()} style={{ backgroundColor: `${data["background-color"]}` }}>
                      {/* <img src={data["background-image"]} alt={data["company-name"]} id="backgroundImage" /> */}
                      {backgroundImage}
                      <p>Upload Background</p>
                      <i className="fas fa-image"></i>
                    </label>
                    <label htmlFor="upload-logo" className="image--file image--file__logo" onClick={() => logoModal.current.openModal()} style={{ backgroundColor: `${data["logo-background-color"]}` }}>
                      <input type="hidden" name="logo" data-ms-member="logo" defaultValue={data.logo} />
                      <input type="hidden" name="logo-background-color" data-ms-member="logo-background-color" defaultValue={data["logo-background-color"]} />
                      {logoImage}
                      <p>Upload Logo</p>
                      <i className="fas fa-camera"></i>
                    </label>
                  </div>
                </div>
              </div>
              <h3>Company Information</h3>
              <p>Filling out this information, will help other members learn more about your company.</p>
              <br />
              <FormInput name="isprimary" type="slider" label="Is this a Primary User?" memberstack="isprimary" onChange={(e) => handleChecked(setIsPrimary(e.currentTarget.checked))} checked={isPrimary} value={`${data.isprimary}`} required />

              <div className="profiles__forms">
                <div className="profiles__forms--cols">
                  <FormInput name="company-name" type="text" required={true} label="Company Name" memberstack="company-name" />

                  <div className="form-field dropdown ">
                    <label htmlFor="industry">Industry</label>
                    <div className="select-container">
                      <div className="select-container__select">
                        <select name="industry" id="industry" data-ms-member="industry">
                          <option value="">- Select -</option>
                          {options.map((e, i) => {
                            return (
                              <option defaultValue={e.value} key={i}>
                                {e.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <FormInput name="company-url" type="text" required label="Company URL" placeholder="ex. https://www.example.com/" memberstack="company-url" />

                  <div className="form-field dropdown ">
                    <label htmlFor="total-of-employees">Company Size</label>
                    <div className="select-container">
                      <div className="select-container__select">
                        <select name="total-of-employees" id="total-of-employees" data-ms-member="total-of-employees">
                          <option value="">- Select -</option>
                          {companySize.map((e, i) => {
                            return (
                              <option defaultValue={e.value} key={i}>
                                {e.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-field dropdown multiselect">
                    <label htmlFor="perks-benefits">Perks + Benefits</label>
                    <input id="hidden-perks-input" type="hidden" defaultValue={JSON.stringify(selectPerks)} data-ms-member="perks-benefits" />
                    <MultiSelect id="perks-benefits" name="perks-benefits" options={perksBenefits} value={selectPerks} onChange={setPerksSelected} overrideStrings={{ selectSomeItems: "- Select -" }} />
                  </div>
                </div>
                <div className="profiles__forms--cols">
                  <FormInput name="address" type="text" label="Street Address" memberstack="address" />
                  <div className="profiles__forms--address">
                    <FormInput name="city" type="text" label="City" memberstack="city" />
                    <div className="form-field dropdown ">
                      <label htmlFor="state">State</label>
                      <div className="select-container">
                        <div className="select-container__select">
                          <select name="state" id="state" data-ms-member="state">
                            <option value="">- Select -</option>
                            {states.map((e, i) => {
                              return (
                                <option defaultValue={e.value} key={i}>
                                  {e.label}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <FormInput name="zip" type="number" label="Zip" memberstack="zip" />
                  </div>
                  <FormInput type="tel" id="phone-number" name="phone-number" label="Phone Number" memberstack="phone-number" placeholder="ex. 262-123-4567" maxlength="12" />
                  <FormInput name="general-contact-email-address" type="email" label="Contact Email" memberstack="general-contact-email-address" />
                </div>
              </div>
              {socialNetwork}
              <div className="company-description">
                <h3>Company Description</h3>
                <p>Let others know more about your company.</p>
                <br />
                <FormInput name="company-description" type="textarea" memberstack="company-description" maxlength="700" />
              </div>
              <div className="form-controls">
                <button type="submit" className="button button--primary" disabled={publishingProfile || !allProfiles.length > 0}>
                  {!publishingProfile ? "Publish" : "Publishing..."}
                </button>
                <Link to="/member/company-profile" className="link">
                  View Profile
                </Link>
              </div>
            </form>

            <div className="profiles__company-story">
              <h3>Company Stories</h3>
              <p>Help candidates get to know you better by adding a brief story about emerging tech applications, community involvement, diversity initiatives, and more to your profile.</p>

              <FeaturedStories />
            </div>
          </div>
        </div>
      </div>
      <Modal ref={logoModal}>
        <ModalModules ref={logoModal} formDisplayed="logoUpload" data={imageData[0].logo} transferColor={(transferedColor) => setTransferedColor(transferedColor)} transferImage={(transferedImage) => setTransferedImage(transferedImage)} />
      </Modal>
      <Modal ref={headerModal}>
        <ModalModules ref={headerModal} formDisplayed="headerUpload" data={imageData[1].header} transferColor={(transferedBgColor) => setTransferedBgColor(transferedBgColor)} transferImage={(transferedBgImage) => setTransferedBgImage(transferedBgImage)} />
      </Modal>
    </Layout>
  )
}

export default CompanyProfileEdit
