import { getTokenAnon as getAToken } from "../../dataAccess/token/getToken"

const url = "https://edit.choosemketech.org"

const mapWordPressToObject = (post) => ({
  id: post.id,
  databaseId: post.id,
  title: post.acf.company_name,
  date: post.date,
  modified: post.modified,
  slug: post.slug,
  companyProfiles: {
    city: post.acf.city,
    companyDescription: post.acf.company_description,
    companyName: post.acf.company_name,
    companyUrl: post.acf.company_url,
    companySize: post.acf.company_size,
    contactEmail: post.acf.contact_email,
    industry: post.acf.industry,
    perksBenefits: post.acf.perks_benefits,
    phoneNumber: post.acf.phone_number,
    state: post.acf.state,
    streetAddress: post.acf.street_address,
    zip: post.acf.zip,
    backgroundColor: post.acf.background_color,
    logoBackgroundColor: post.acf.logo_background_color,
    logoAlt: post.acf.logo_alt,
    logoUrl: post.acf.logo,
    backgroundAlt: post.acf.background_image_alt,
    backgroundUrl: post.acf.background_image,
    linkedIn: post.acf.linkedin,
    facebook: post.acf.facebook,
    twitter: post.acf.twitter,
    membership: post.acf.membership_type,
    primaryUser: post.acf.primary_user,
    memberstackId: post.acf.memberstack_id,
  },
})

export const create = async (memberstackId, city, companyDescription, companyName, companyUrl, companySize, contactEmail, industry, perksBenefits, phoneNumber, state, streetAddress, zip, backgroundColor, logoBackgroundColor, logoAlt, logoUrl, backgroundAlt, backgroundUrl, linkedIn, facebook, twitter, membership, primaryUser) => {
  return fetch(url + "/wp-json/wp/v2/company_profile/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
    body: JSON.stringify({
      title: companyName,
      slug: companyName,
      fields: {
        memberstack_id: memberstackId,
        city: city,
        company_description: companyDescription,
        company_name: companyName,
        company_url: companyUrl,
        company_size: companySize,
        contact_email: contactEmail,
        industry: industry,
        perks_benefits: perksBenefits,
        phone_number: phoneNumber,
        state: state,
        street_address: streetAddress,
        zip: zip,
        background_color: backgroundColor,
        logo_background_color: logoBackgroundColor,
        logo_alt: logoAlt,
        logo: logoUrl,
        background_image_alt: backgroundAlt,
        background_image: backgroundUrl,
        linkedin: linkedIn,
        facebook: facebook,
        twitter: twitter,
        membership_type: membership,
        primary_user: primaryUser,
      },
      status: "publish",
    }),
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (jobpost) {
      console.log(jobpost)
    })
}

export const getAll = async () => {
  const posts = await fetch(url + `/wp-json/wp/v2/company_profile/?per_page=100`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })
  return (await posts.json()).map(mapWordPressToObject)
}

export const get = async (id) => {
  const post = await fetch(url + `/wp-json/wp/v2/company_profile/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })
  return mapWordPressToObject(await post.json())
}

export const deleteJobPosting = async (id) =>
  fetch(url + `/wp-json/wp/v2/company_profile/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })

export const update = async (id, jobPosting) =>
  fetch(url + `/wp-json/wp/v2/company_profile/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
    body: JSON.stringify(jobPosting),
  })
