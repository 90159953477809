import React, { useState, useEffect, useRef } from "react"
import FormInput from "./FormInput"
import { Notification } from "./ToastNotification"
import Modal from "./Modal"
import MemberFeaturedStories from "./MemberFeaturedStories"
import ModalModules, { purchaseAdditionalStory as purchaseAdditionalForm } from "./ModalModules"
import { getAll as getAllStories, create as createFeaturedStory, deleteJobPosting } from "../dataAccess/wordPress/featuredStories"
import { additionaJobPostingSku } from "../stripe"
import { v4 as uuid } from "uuid"
import axios from "axios"
import { getTokenAnon as getAToken } from "../dataAccess/token/getToken"

const maxPostsByMembership = {
  Entry: 0,
  "Community (Entry)": 0,
  Base: 1,
  "Bronze (Base)": 1,
  Enhanced: 3,
  Premium: 5,
}

const FeaturedStories = ({ buyButton, featuredImage }) => {
  const imgUploadUpload = useRef()
  const [img, setImg] = useState()
  const [data, setMemberData] = useState()
  const [allPostings, setAllPostings] = useState([])
  const purchaseAdditionalRef = useRef()
  const [savingFeaturedStory, setSavingFeaturedStory] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isFeaturedStory, setIsFeaturedStory] = useState(false)

  const membershipLevel = data?.membership.name
  const maxPostsAllowed = maxPostsByMembership[membershipLevel ?? "Entry"]
  const postCount = allPostings.map((p) => p.title).reduce((total, id) => (id === data?.id ? total + 1 : total), 0)
  const additionalPurchases = () => {
    if (isNaN(parseInt(data?.addtionalstorypurchases))) {
      return 0
    }
    window.MemberStack.reload()
    return parseInt(data?.addtionalstorypurchases)
  }
  const postingDisallowed = postCount >= maxPostsAllowed + additionalPurchases()
  const boughtPost = postCount >= maxPostsAllowed
  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  useEffect(() => {
    async function setStories() {
      setAllPostings(await getAllStories())
    }
  }, [])

  if (postingDisallowed) {
    buyButton = (
      <a href="" className="button button--primary" onClick={(e) => purchaseAdditional(e)}>
        Buy a Story
      </a>
    )
  } else {
    buyButton = (
      <button type="submit" className="button button--primary" disabled={savingFeaturedStory}>
        {!savingFeaturedStory ? "Submit Featured Story" : "Saving Featured Story..."}
      </button>
    )
  }

  let acfIsFeaturedStory

  const handleChecked = () => {
    if (isFeaturedStory) {
      acfIsFeaturedStory = "off"
    } else {
      acfIsFeaturedStory = "on"
    }
  }

  const updateFeaturedStory = async (e) => {
    e.preventDefault()
    setSavingFeaturedStory(true)
    if (postingDisallowed) {
      return Notification("Sorry! You've reached your featured story limit. You will be redirected to purchse more stories.", "fas fa-times-circle", "error")
    }

    const formData = new FormData(e.target)
    const obj = {}

    for (const entry of formData.entries()) {
      obj[entry[0]] = entry[1]
    }

    const now = new Date()
    const startDate = new Date(now.toUTCString())
    const endDate = new Date(new Date(new Date().setDate(now.getDate() + 30)).toUTCString())

    const imageUrl = await uploadImage(obj.story_image)
    if (imageUrl !== "") {
      if (boughtPost) {
        await createFeaturedStory(data.id, obj.story_header, obj.story_description, startDate, endDate, imageUrl, obj.story_featured)
      } else {
        await createFeaturedStory(data.id, obj.story_header, obj.story_description, "", "", imageUrl, obj.story_featured)
      }
      Notification("success! featured story has been created", "fas fa-check-circle", "success")
    } else {
      Notification("failure! featured story has not been created", "fas fa-check-circle", "failure")
    }
    setAllPostings(await getAllStories())
    setSavingFeaturedStory(false)
  }

  const purchaseAdditional = () => {
    purchaseAdditionalRef.current.openModal()
  }

  const onAdditionalPurchased = async (additionalCount) => {
    if (!data) {
      Notification("We were unable to process your purchase. Please contact customer support.", "fas fa-times-circle", "error")
      return
    }

    const now = new Date()
    const utcNow = new Date(now.toUTCString())
    const utcNowPlusThirty = new Date(new Date(new Date().setDate(now.getDate() + 30)).toUTCString())
    const additionalPurchaseFactory = () => ({
      token: uuid(),
      expirationDate: utcNowPlusThirty.toUTCString(),
    })

    const newAdditionalPurchases = Array(additionalCount)
      .fill(additionalPurchaseFactory)
      .map((f) => f())
    const additionalPurchases = (data.additionalPostPurchases?.filter((p) => new Date(p.expirationDate) > utcNow) ?? []).concat(newAdditionalPurchases)

    await data.updateProfile({ addtionalstorypurchases: additionalPurchases }, true)

    window.MemberStack.reload()
    setMaxPostsAllowed(maxPostsAllowed + additionalCount)
    purchaseAdditionalRef.current.closeModal()
  }

  const previewImage = () => {
    setImg(imgUploadUpload.current.files[0])
  }

  const uploadImage = async (file) => {
    let fileReader = new FileReader()

    let error = false
    const imageUrl = await sendGetRequest(file)

    fileReader.readAsDataURL(file)

    return imageUrl
  }

  const sendGetRequest = async (file) => {
    try {
      let url = "https://edit.choosemketech.org"
      let formData = new FormData()

      formData.append("file", file)
      const source_url = await axios
        .post(url + "/wp-json/wp/v2/media", formData, {
          headers: {
            "Content-Disposition": "attachment; filename='" + file.name + "'",
            "Content-Type": "image/png, image/jpeg, image/svg+xml",
            authorization: `Bearer ${await getAToken()}`,
          },
        })
        .then((response) => {
          return response.data.source_url
        })
      return source_url
    } catch (err) {
      console.error(err)
    }
  }

  if (img) {
    featuredImage = <img src={img ? URL.createObjectURL(img) : null} alt={img ? img.name : null} />
  }

  let today = new Date()
  for (let item of allPostings) {
    if (item.featuredStories.startDate != "Invalid Date" && item.featuredStories.endDate != "Invalid Date") {
      if (today.getDate() >= item.featuredStories.endDate.getDate()) {
        deleteJobPosting(item.databaseId)
        const additionalPurchases = data.addtionalstorypurchases - 1
        data.updateProfile({ addtionalstorypurchases: additionalPurchases }, true)
      } else {
        //console.log("Not Expired!")
      }
    }
  }

  return (
    <>
      <form id="featuredStory" onSubmit={updateFeaturedStory}>
        <FormInput name="story_header" type="text" required label="Featured Story Header" />
        <FormInput name="story_description" type="textarea" required label="Featured Story Content" maxlength="700" />
        <FormInput name="story_featured" type="slider" label="Feature this story?" />
        <div className="form-field story_image">
          <div className="story_image-upload">
            <label>Upload Featured Image</label>
            <input name="story_image" id="imgUploadUpload" type="file" accept="image/png, image/jpeg, image/svg+xml" ref={imgUploadUpload} onChange={previewImage} />
            {featuredImage}
          </div>
        </div>

        <div className="form-controls">{buyButton}</div>
        {data && <MemberFeaturedStories allPostings={allPostings} onMemberPostsChanged={() => getAllStories().then((postings) => setAllPostings(postings))} data={data} />}
      </form>
      <Modal ref={purchaseAdditionalRef}>
        <ModalModules ref={purchaseAdditionalRef} formDisplayed={purchaseAdditionalForm} title="Purchase Additional Stories" stripeSku={additionaJobPostingSku} onAdditionalPurchased={onAdditionalPurchased}>
          Additional Featured Stories cost $100 each per month.
        </ModalModules>
      </Modal>
    </>
  )
}

export default FeaturedStories
