import React, { useState, useEffect, useRef } from "react"
import { get as getJobPosting, update as updateJobPosting, deleteJobPosting } from "../dataAccess/wordPress/featuredStories"
import FeaturedStoryCard from "./FeaturedStoryCard"
import Modal from "./Modal"
import ModalModules, { editStoryForm, deleteStoryForm } from "./ModalModules"

const MembershipFeaturedStories = ({ allPostings, onMemberPostsChanged, data }) => {
  const [memberPosts, setMemberPosts] = useState([])
  const [company, setCompany] = useState()
  const [editingPost, setEditingPost] = useState()
  const [deletingPost, setDeletingPost] = useState()
  const editPostingRef = useRef()
  const deletePostingRef = useRef()

  const editPostFactory = (post) => () => {
    setEditingPost(post)
    editPostingRef.current.openModal()

    return false
  }

  const onPostEdited = async (post) => {
    if (!post) return

    const story = post.featuredStories

    const fullPost = await getJobPosting(post.databaseId)
    await updateJobPosting(post.databaseId, {
      ...fullPost,
      modified: new Date(),
      fields: {
        ...fullPost.acf,
        story_header: story.featuredHeader,
        story_description: story.featuredDescription,
        starttime: story.startDate,
        endtime: story.endDate,
        story_image: story.featuredImage,
        story_featured: story.featuredStory,
      },
    })

    const editedIndex = memberPosts.findIndex((p) => p.id === editingPost.id)
    setMemberPosts([...memberPosts.slice(0, editedIndex), post, ...memberPosts.slice(editedIndex + 1)])
    editPostingRef.current.closeModal()
  }

  const deletePostFactory = (post) => (e) => {
    e.preventDefault()
    setDeletingPost(post)
    deletePostingRef.current.openModal()

    return false
  }

  const onPostDeleted = async (post) => {
    await deleteJobPosting(post.databaseId)
    setMemberPosts(memberPosts.filter((p) => p.id !== post.id))
    deletePostingRef.current.closeModal()
    onMemberPostsChanged()
  }

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then((member) => {
          setMemberPosts(allPostings.filter((p) => p.title === member.id))
        })
        .catch((e) => console.log(e))
    }
  }, [allPostings])

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then((member) => {
          setCompany({
            name: member["company-name"],
            url: member["company-url"],
            city: member.city,
            state: member.state,
          })
        })
        .catch((e) => console.log(e))
    }
  }, [])

  return (
    <div>
      <Modal ref={editPostingRef}>
        <ModalModules ref={editPostingRef} formDisplayed={editStoryForm} jobPosting={editingPost} onPostingEdited={onPostEdited} />
      </Modal>
      <Modal ref={deletePostingRef}>
        <ModalModules ref={deletePostingRef} formDisplayed={deleteStoryForm} jobPosting={deletingPost} onPostingDeleted={onPostDeleted} />
      </Modal>
      {memberPosts
        .sort((p1, p2) => p1.modified < p2.modified)
        .map((p) => (
          <FeaturedStoryCard key={p.id} posting={p} company={company} onEdit={editPostFactory(p)} onDelete={deletePostFactory(p)} data={data} />
        ))}
    </div>
  )
}

export default MembershipFeaturedStories
