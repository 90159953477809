import React from "react"

const FeaturedStoryCard = ({ posting, onEdit, onDelete, data, posted, featuredImage }) => {
  const { featuredStories: story } = posting
  // This shows expiration of bought posts in days
  if (!isNaN(new Date(posting.featuredStories.endDate)) && data.id === posting.title) {
    const day = 24 * 60 * 60 * 1000
    const startDate = new Date(posting.featuredStories.startDate)
    const endDate = new Date(posting.featuredStories.endDate)
    const result = Math.round((endDate - startDate) / day)
    posted = (
      <span>
        Posted {new Date(posting.date).toDateString()} - Expires in {result} days
      </span>
    )
  } else {
    posted = <span>Posted {new Date(posting.date).toDateString()}</span>
  }

  if (story.featuredImage) {
    featuredImage = (
      <div className="story-card__right">
        <img src={story.featuredImage} alt="image"></img>
      </div>
    )
  }

  return (
    <div className="card story-card story-card--featured">
      <div className="story-card__left">
        <div className="story-card__left__heading">
          <h3>{story.featuredHeader}</h3>
        </div>
        <p>{story.featuredDescription}</p>
        <div className="story-card__right__controls">
          <span className="control" onClick={onEdit}>
            edit
          </span>{" "}
          |{" "}
          <span className="control" onClick={onDelete}>
            delete
          </span>
        </div>
        <div className="story-card__left__tags">{story.featuredStory && <span className="tag tag--gold tag--gold--featured">Featured</span>}</div>
        <div className="story-card__left__date">{posted}</div>
      </div>
      {featuredImage}
    </div>
  )
}

export default FeaturedStoryCard
